<template>
  <div class="tarea">
    <div class="aplicacion-tratamiento-dia border mb-4 p-2"> 
      <div class="row d-flex">
        <div class="col-auto">
          <router-link
            :to="{
              name: 'animal',
              params: {
                nombre: tratamiento_prop.nombre_animal,
                id: tratamiento_prop.id_animal,
              },
            }"
            ><img
              :src="usuario.url_web + tratamiento_prop.src_foto"
              class="mr-3"
            />
          </router-link>
        </div>
        <div class="col -sm-3 tarea-contenido">
          <div>
            <h5>
              <router-link
                :to="{
                  name: 'animal',
                  params: {
                    nombre: tratamiento_prop.nombre_animal,
                    id: tratamiento_prop.id_animal,
                  },
                }"
                >{{ tratamiento_prop.nombre_animal }}
              </router-link>
            </h5>
          </div>
          <div>
            <strong>{{ tratamiento_prop.tratamiento }}</strong>
            <br>
            <router-link 
            class="btn btn-sm btn-secondary"
            :to="{
                  name: 'Historial Tratamiento',
                  params: {
                    nombre: tratamiento_prop.nombre_animal,
                    id_med: tratamiento_prop.id_med,
                    id_animal: tratamiento_prop.id_animal
                  },
                }"
            >
              ver tratamiento
            </router-link>
          </div>
          <div>{{ tratamiento_prop.posologia }}</div>
          <div>
            Cada {{ tratamiento_prop.intervalo }}
            {{ tratamiento_prop.periodo == "horas" ? "horas" : "días" }}
          </div>
          <div>
            {{ tratamiento_prop.aplicaciones_por_dia }}
            {{ tratamiento_prop.aplicaciones_por_dia == 1 ? "vez" : "veces" }}
            al día
          </div>
        </div>
        <div class="col-auto ml-auto mr-2 p-2 align-self-end">
          <div 
            v-if="tratamiento_prop.ultima_fecha_aplicada"
            class="text-start p-1 text-muted">
            Última aplicación: {{ fecha_mostrar(tratamiento_prop.ultima_fecha_aplicada) }}</div>
          <div
            v-if="tratamiento_prop.regularizado"
            class="alert border border-info text-info mt-1"
          >
            {{ tratamiento_prop.regularizado }}
          </div>
          <div
            v-if="tratamiento_prop.fecha_finalizacion_inicial"
            class="col-12 alert alert-warning mt-1"
          >
            La fecha de finalización se ha modificado.<br />
            Finalización inicial:
            {{ tratamiento_prop.fecha_finalizacion_inicial }}<br />
            Finalización nueva: {{ tratamiento_prop.fecha_finalizacion }}
          </div>
          <TareaAplicacion
            v-for="(item, index) in aplicacionesDia"
            :key="index"
            :aplicacion_tratamiento_prop="item"
            :nombre_animal_prop="tratamiento_prop.nombre_animal"
            :src_foto_prop="tratamiento_prop.src_foto"
            :nombre_tratamiento_prop="tratamiento_prop.tratamiento"
            :posologia_prop="tratamiento_prop.posologia"
            
          >
          </TareaAplicacion>
        </div>
      </div>
      <!-- row-->
    </div>
    <!-- aplicacion tratamiento día -->
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapGetters: tareasMapGetters } = createNamespacedHelpers("tareas");
const { mapGetters: userMapGetters } = createNamespacedHelpers("user");
import TareaAplicacion from "@/components/TareaAplicacion";

export default {
  name: "Tarea",
  data() {
    return {
      id_animal: 0,
      id_med: 0,
      tarea_modificar_activa: false,
      mostrar_form: 0,
    };
  },
  components: { TareaAplicacion },
  props: {
    id_animal_prop: { default: 0 },
    id_med_prop: { default: 0 },
    tratamiento_prop: { default: false },
  },
  methods: {
    mostrarTratamiento() {},
    fecha_mostrar(fecha){
      if(fecha){
        return this.$root.fechaInvertir(fecha)
      }
    },
  },
  created() {},
  mounted() {
    this.id_animal = this.id_animal_prop;
    this.id_med = this.id_med_prop;
  },
  computed: {
    ...tareasMapGetters(["fecha_hoy"]),
    ...tareasMapGetters(["fecha_consulta"]),
    ...tareasMapGetters(["tratamientos_activos"]),
    ...tareasMapGetters(["tratamientos_activos_por_fechas"]),
    ...userMapGetters(["user","user_loged_in","usuario", "nombre_usuario"]),
    aplicacionesDia() {
      try {
        return this.tratamientos_activos_por_fechas[this.fecha_consulta][
          "t_" + this.id_med_prop
        ].aplicaciones_dia;
      } catch {
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
.aplicacion-tratamiento-dia {
  max-width: 900px;
  margin: auto;
  img{max-width: 110px;}
}
.aplicacion-tratamiento-dia.border:hover {
  border-color: #17a2b8 !important;
}
.dia_semana,
.aplicacion-tratamiento-dia,
.ficha-animal {
  opacity: 1;
  animation: fadeIn 1s ease;
}
.tarea-contenido {
  text-align: left;
}
</style>