<template>
  <div id="tareas">
    <b-container fluid="md">
      <b-button-toolbar class="mb-1 d-flex justify-content-end" aria-label="Toolbar with button groups and dropdown menu"
      >
        <b-button-group size="sm" class="mb-3">
          <b-button>
            <input
              type="date"
              v-model="cambiar_dia"
              class="form-control form-control-sm w-auto"
            />
          </b-button>
          <b-button @click="cambiarDia()">Ver fecha</b-button>
        </b-button-group>
      </b-button-toolbar>

      <h1>Tareas para {{ tareasParaDia }}</h1>

       <div v-if="animalesActivosFechaConsulta"  class="tareas-animales-activos">
        <TareasAnimalActivo
          v-for="(item, index) in animalesActivosFechaConsulta"
          :key="index"
          :id_animal_prop="item.id_animal"
          :tratamientos_prop="item.tratamientos"
        ></TareasAnimalActivo>
      </div>
      <div v-if="!tratamientosActivosFecha">
        <p class="h5 mt-4">No se encontró actividad</p>
      </div>
  
    </b-container>
  </div>
</template>

<script>
import TareasAnimalActivo from "@/components/TareasAnimalActivo";
import { mapGetters, createNamespacedHelpers } from "vuex";
const { mapGetters: tareasMapGetters, mapActions: tareasMapActions } =
  createNamespacedHelpers("tareas");

const { mapGetters: userMapGetters } = createNamespacedHelpers("user");
const { mapGetters: fechaslibMapGetters, mapActions: fechaslibMapActions } =
  createNamespacedHelpers("fechaslib");

export default {
  name: "Tareas",
  components: {
    TareasAnimalActivo,
  },
  data() {
    return {
      mostrar_dia: "hoy",
      cambiar_dia: false,
    };
  },
  props: {},
  mounted() {
    if(!this.user_loged_in){
        //this.$router.push({ name: 'Login'})
      }
    else{
      if(this.puede_ver_tareas){
        this.iniciar();
      }
      else{
        this.$router.push({ name: 'Home'})
      }
        
    }
    
  },
  methods: {
    ...fechaslibMapActions(["GET_LIB_FECHAS"]),
    ...tareasMapActions(["GET_TAREAS"]),
    iniciar(){
      this.mostrar_dia = this.FechaAMostrar();
      //console.log('mostrar dia: ' + this.mostrar_dia)
      this.GET_TAREAS(this.mostrar_dia);
      //this.GET_LIB_FECHAS();
      this.cambiar_dia = this.fecha_consulta;
      document.title = 'Tareas ' ;
    },
    cambiarDia() {
      if (this.cambiar_dia != "") {
        if (this.cambiar_dia != this.mostrar_dia) {
          this.mostrar_dia = this.cambiar_dia;
          this.$router.push({ name: 'Tareas por fecha', params: { fecha: this.mostrar_dia} }) 
          //this.GET_TAREAS(this.mostrar_dia);
        }
      }
    },
    actualizarRoutes() {
      this.cambiarDia()
    },
    FechaAMostrar() {
      if (this.$route.name=='Tareas por fecha') {
        //console.log(this.$route.params);
    
         return this.$route.params.fecha;
      }
      else return this.fecha_hoy;
      //return this.mostrar_dia;
    },
  },
  computed: {
    ...mapGetters(["url_site_local"]),  
    ...userMapGetters(['user_loged_in','usuario','nombre_usuario',]),
    ...userMapGetters(['puede_ver_tareas', 'puede_aplicar_tareas']),
    ...tareasMapGetters(["fecha_hoy"]),
    ...tareasMapGetters(["fecha_consulta"]),
    ...tareasMapGetters(["tratamientos_activos_por_fechas"]),
    ...tareasMapGetters(["tratamientos_activos"]),
    ...tareasMapGetters(["animales_activos"]),
    ...fechaslibMapGetters(["fechas_lib"]),
    tratamientosActivosFecha() {
      if (this.tratamientos_activos_por_fechas && Object.keys(this.tratamientos_activos_por_fechas).length && this.fecha_consulta) {
        return this.tratamientos_activos_por_fechas[this.fecha_consulta];
      }
      return false;
    },
    animalesActivosFechaConsulta(){
      let animales = {};
      if(this.tratamientosActivosFecha){
        //console.log(this.tratamientosActivosFecha) 
        let keys = Object.keys(this.tratamientosActivosFecha) //trae undefined si la fecha no está en el json al pedir un rango externo a las 3 semanas. Hay que asegurarse que la fecha presente se incluya (creo que arreglado 11-02-2022)
        //console.log(keys)
        keys.forEach(element => {

          let idanimal = this.tratamientosActivosFecha[element].id_animal
          let idmed = this.tratamientosActivosFecha[element].id
          let animal = {
            'id_animal' : idanimal,
            'tratamientos': []
            }
            
            let tratamiento = {'id_med' : idmed}
            
            let index = 
                this.tratamientos_activos
                  .findIndex(trat => trat.id_med === idmed);
            
            if(index > -1){
              tratamiento = this.tratamientos_activos[index]
            }

            if('animal_' + idanimal in animales){
              animales['animal_' + idanimal].tratamientos.push(tratamiento)
            }
            else{
              animales['animal_' + idanimal]=animal;
              animales['animal_' + idanimal].tratamientos.push(tratamiento)
            }
            
          
        });
    }
      return animales
    },
    tareasParaDia() {
      let mostrar = " el " + this.$root.fechaInvertir(this.fecha_consulta) + " ";
      if (this.fecha_consulta == this.fecha_hoy) {
        mostrar = "hoy ";
      }
      
      try { 
        let nombre_dia_hoy = this.fechas_lib.semana_presente.fechas[this.fecha_hoy]
        let nombre_dia = nombre_dia_hoy=='domingo' ? 
            (this.fecha_consulta==this.fechas_lib.fecha_ayer) ? 
                  this.fechas_lib.semana_presente.fechas[this.fecha_consulta] 
                  :this.fechas_lib.semana_proxima.fechas[this.fecha_consulta]
            : this.fechas_lib.semana_presente.fechas[this.fecha_consulta];

        if(nombre_dia != undefined)  mostrar += nombre_dia;

        if (this.fecha_consulta == this.fechas_lib.fecha_ayer) {
          mostrar = "ayer " ;
          if(nombre_dia != undefined)  mostrar += nombre_dia;
        }
        if (this.fecha_consulta == this.fechas_lib.fecha_maniana) {
          mostrar = "mañana " + nombre_dia;
        }
      } catch {
        return mostrar;
      }
      return mostrar;
    },
    FechaRoute(){
      if(this.$route.params.fecha) return this.$route.params.fecha
      else return this.fecha_hoy
    }
  },
  watch: {
    mostrar_dia: function () {
      //this.GET_TAREAS(this.mostrar_dia);
    },
    fecha_consulta: function () {
      this.cambiar_dia = this.fecha_consulta;
      this.mostrar_dia = this.fecha_consulta;
      
    }, 
    FechaRoute: function(){
      console.log('cambio route')
      this.cambiar_dia = this.FechaRoute
      //this.GET_TAREAS(this.FechaRoute);
       
    },
    $route: function () {
     // this.actualizarRoutes();
    },
    user_loged_in: function(){
      if(this.user_loged_in) this.iniciar();
    }
  },
};
</script>
<style lang="scss" scoped>
  #tareas{
    min-height: 500px;
  }
</style>