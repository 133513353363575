var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tarea"},[_c('div',{staticClass:"aplicacion-tratamiento-dia border mb-4 p-2"},[_c('div',{staticClass:"row d-flex"},[_c('div',{staticClass:"col-auto"},[_c('router-link',{attrs:{"to":{
            name: 'animal',
            params: {
              nombre: _vm.tratamiento_prop.nombre_animal,
              id: _vm.tratamiento_prop.id_animal,
            },
          }}},[_c('img',{staticClass:"mr-3",attrs:{"src":_vm.usuario.url_web + _vm.tratamiento_prop.src_foto}})])],1),_c('div',{staticClass:"col -sm-3 tarea-contenido"},[_c('div',[_c('h5',[_c('router-link',{attrs:{"to":{
                name: 'animal',
                params: {
                  nombre: _vm.tratamiento_prop.nombre_animal,
                  id: _vm.tratamiento_prop.id_animal,
                },
              }}},[_vm._v(_vm._s(_vm.tratamiento_prop.nombre_animal)+" ")])],1)]),_c('div',[_c('strong',[_vm._v(_vm._s(_vm.tratamiento_prop.tratamiento))]),_c('br'),_c('router-link',{staticClass:"btn btn-sm btn-secondary",attrs:{"to":{
                name: 'Historial Tratamiento',
                params: {
                  nombre: _vm.tratamiento_prop.nombre_animal,
                  id_med: _vm.tratamiento_prop.id_med,
                  id_animal: _vm.tratamiento_prop.id_animal
                },
              }}},[_vm._v(" ver tratamiento ")])],1),_c('div',[_vm._v(_vm._s(_vm.tratamiento_prop.posologia))]),_c('div',[_vm._v(" Cada "+_vm._s(_vm.tratamiento_prop.intervalo)+" "+_vm._s(_vm.tratamiento_prop.periodo == "horas" ? "horas" : "días")+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.tratamiento_prop.aplicaciones_por_dia)+" "+_vm._s(_vm.tratamiento_prop.aplicaciones_por_dia == 1 ? "vez" : "veces")+" al día ")])]),_c('div',{staticClass:"col-auto ml-auto mr-2 p-2 align-self-end"},[(_vm.tratamiento_prop.ultima_fecha_aplicada)?_c('div',{staticClass:"text-start p-1 text-muted"},[_vm._v(" Última aplicación: "+_vm._s(_vm.fecha_mostrar(_vm.tratamiento_prop.ultima_fecha_aplicada)))]):_vm._e(),(_vm.tratamiento_prop.regularizado)?_c('div',{staticClass:"alert border border-info text-info mt-1"},[_vm._v(" "+_vm._s(_vm.tratamiento_prop.regularizado)+" ")]):_vm._e(),(_vm.tratamiento_prop.fecha_finalizacion_inicial)?_c('div',{staticClass:"col-12 alert alert-warning mt-1"},[_vm._v(" La fecha de finalización se ha modificado."),_c('br'),_vm._v(" Finalización inicial: "+_vm._s(_vm.tratamiento_prop.fecha_finalizacion_inicial)),_c('br'),_vm._v(" Finalización nueva: "+_vm._s(_vm.tratamiento_prop.fecha_finalizacion)+" ")]):_vm._e(),_vm._l((_vm.aplicacionesDia),function(item,index){return _c('TareaAplicacion',{key:index,attrs:{"aplicacion_tratamiento_prop":item,"nombre_animal_prop":_vm.tratamiento_prop.nombre_animal,"src_foto_prop":_vm.tratamiento_prop.src_foto,"nombre_tratamiento_prop":_vm.tratamiento_prop.tratamiento,"posologia_prop":_vm.tratamiento_prop.posologia}})})],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }