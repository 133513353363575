<template>
    <div v-if="tratamientos_prop" class="animal-activo"> 
        <Tarea v-for="(item, index) in tratamientos_prop" :key="index"
            :id_animal_prop="id_animal_prop"
            :id_med_prop="item.id_med"
            :tratamiento_prop="item"
            
    ></Tarea>
    </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapGetters: tareasMapGetters } = createNamespacedHelpers("tareas");
const { mapGetters: userMapGetters } = createNamespacedHelpers("user");

import Tarea from "@/components/Tarea";
export default {
  name: "TareasAnimalActivo",
  components: {
    Tarea,
  },
  data() {
    return {
      id_animal: 0,
      nombre_animal: "",
    };
  },
  props:{
      id_animal_prop: { defaul: 0},
      nombre_animal_prop: { defaul: ''},
      tratamientos_prop: { defaul: false}
  },
  computed: {
    ...tareasMapGetters(["tratamientos_activos"]),
    ...tareasMapGetters(["fecha_hoy"]),
    ...tareasMapGetters(["fecha_consulta"]),
    ...userMapGetters(["user", "user_loged_in","usuario", "nombre_usuario"]),
    /*
    tratamientosActivosAnimal() {
      if (this.tratamientos_activos) {
        return this.tratamientos_activos.filter(
          (obj) => obj.id_animal == this.id_animal_prop
        );
      }
      return false;
    },
*/
  },
  mounted() {
    this.id_animal = this.id_animal_prop;
    this.nombre_animal = this.nombre_animal_prop;
  },
};
</script>

<style>
</style>